// Import the Firebase libraries
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

// // Firebase configuration from your Firebase console
const firebaseConfig = {
  apiKey: "AIzaSyCXWvXfpav5zDEIgdCYB4zJw_rM1t3sbo4",
  authDomain: "mseconsultant-ea5a1.firebaseapp.com",
  projectId: "mseconsultant-ea5a1",
  storageBucket: "mseconsultant-ea5a1.firebasestorage.app",
  messagingSenderId: "67275010531",
  appId: "1:67275010531:web:a63cf9245f249bdda4b0d6",
  measurementId: "G-NC2JV7PGQ3",
};

// // Initialize Firebase and Authentication
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup, analytics, logEvent };
