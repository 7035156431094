import React from "react";
import { Globe2, Users, Target, BookOpen } from "lucide-react";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import gujaratMap from "../assets/gujaratMap.png";
import meeting from "../assets/meeting.jpg";
import ContactForm from "../components/ContactForm";
import aboutImage1 from "../assets/About/1.jpeg";
import aboutImage2 from "../assets/About/2.jpeg";
const AboutPage = () => {
  const team = [
    {
      name: "Suchit Parikh",
      role: "Education Consultant",
      image: "",
    },
    {
      name: "Kaushik Brahmabhatt",
      role: "Student Success Manager",
      image: "",
    },
  ];

  return (
    <section class="py-24 relative xl:mr-0 lg:mr-5 mr-0">
      <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
        <div class="w-full justify-start items-center xl:gap-12 gap-10 grid lg:grid-cols-2 grid-cols-1">
          <div class="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
            <div class="w-full flex-col justify-center items-start gap-8 flex">
              <div class="flex-col justify-start lg:items-start items-center gap-4 flex">
                <h6 class="text-gray-400 text-base font-normal leading-relaxed">
                  About Us
                </h6>
                <div class="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                  <h2 class="text-primary-brown text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                    The Tale of Our Achievement Story
                  </h2>
                  <p class="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                    Our achievement story is a testament to teamwork and
                    perseverance. Together, we've overcome challenges,
                    celebrated victories, and created a narrative of progress
                    and success.
                  </p>
                </div>
              </div>
              <div class="w-full flex-col justify-center items-start gap-6 flex">
                <div class="w-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                  <div class="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 class="text-gray-900 text-2xl font-bold font-manrope leading-9">
                      33+ Years
                    </h4>
                    <p class="text-gray-500 text-base font-normal leading-relaxed">
                      Influencing Digital Landscapes Together
                    </p>
                  </div>
                  <div class="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 class="text-gray-900 text-2xl font-bold font-manrope leading-9">
                      125+ Projects
                    </h4>
                    <p class="text-gray-500 text-base font-normal leading-relaxed">
                      Excellence Achieved Through Success
                    </p>
                  </div>
                </div>
                <div class="w-full h-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                  <div class="w-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 class="text-gray-900 text-2xl font-bold font-manrope leading-9">
                      26+ Awards
                    </h4>
                    <p class="text-gray-500 text-base font-normal leading-relaxed">
                      Our Dedication to Innovation Wins Understanding
                    </p>
                  </div>
                  <div class="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 class="text-gray-900 text-2xl font-bold font-manrope leading-9">
                      99% Happy Clients
                    </h4>
                    <p class="text-gray-500 text-base font-normal leading-relaxed">
                      Mirrors our Focus on Client Satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full lg:justify-start justify-center items-start flex">
            <div class="sm:w-[564px] w-full sm:h-[646px] h-full sm:bg-gray-100 rounded-3xl sm:border border-gray-200 relative">
              <img
                class="sm:mt-5 sm:ml-5 w-full h-full rounded-3xl object-cover"
                src={aboutImage2}
                alt="about Us image"
              />
            </div>
          </div>
        </div>
      </div>
      <section class="py-24 relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div class="w-full justify-start items-center gap-12 grid lg:grid-cols-2 grid-cols-1">
            <div class="w-full justify-center items-start gap-6 grid sm:grid-cols-2 grid-cols-1 lg:order-first order-last">
              <div class="pt-24 lg:justify-center sm:justify-end justify-start items-start gap-2.5 flex">
                <img
                  class=" rounded-xl object-cover"
                  src={aboutImage1}
                  alt="about Us image"
                />
              </div>
              <img
                class="sm:ml-0 ml-auto rounded-xl object-cover"
                src={aboutImage2}
                alt="about Us image"
              />
            </div>
            <div class="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
              <div class="w-full flex-col justify-center items-start gap-8 flex">
                <div class="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                  <h2 class="text-primary-brown text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                    Empowering Each Other to Succeed
                  </h2>
                  <p class="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                    Every project we've undertaken has been a collaborative
                    effort, where every person involved has left their mark.
                    Together, we've not only constructed buildings but also
                    built enduring connections that define our success story.
                  </p>
                </div>
                <div class="w-full lg:justify-start justify-center items-center sm:gap-10 gap-5 inline-flex">
                  <div class="flex-col justify-start items-start inline-flex">
                    <h3 class="text-primary-brown text-4xl font-bold font-manrope leading-normal">
                      33+
                    </h3>
                    <h6 class="text-gray-500 text-base font-normal leading-relaxed">
                      Years of Experience
                    </h6>
                  </div>
                  <div class="flex-col justify-start items-start inline-flex">
                    <h4 class="text-primary-brown text-4xl font-bold font-manrope leading-normal">
                      125+
                    </h4>
                    <h6 class="text-gray-500 text-base font-normal leading-relaxed">
                      Successful Projects
                    </h6>
                  </div>
                  <div class="flex-col justify-start items-start inline-flex">
                    <h4 class="text-primary-brown text-4xl font-bold font-manrope leading-normal">
                      52+
                    </h4>
                    <h6 class="text-gray-500 text-base font-normal leading-relaxed">
                      Happy Clients
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="min-h-screen bg-white">
        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="mb-16">
                <h2 className="text-4xl font-bold mb-8">
                  Our <span className="text-primary-brown">Mission</span>
                </h2>
                <div className="space-y-8">
                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/3">
                      <h3 className="text-xl font-semibold text-gray-800">
                        Access to International Education
                      </h3>
                    </div>
                    <div className="md:w-2/3">
                      <p className="text-gray-600">
                        Aim to make international education accessible to all
                        aspiring students across India, regardless of their
                        socio-economic background.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/3">
                      <h3 className="text-xl font-semibold text-gray-800">
                        Comprehensive Support
                      </h3>
                    </div>
                    <div className="md:w-2/3">
                      <p className="text-gray-600">
                        Provide end-to-end support including admission, visa
                        documentation, travel arrangements, and accommodation
                        for students.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/3">
                      <h3 className="text-xl font-semibold text-gray-800">
                        Guidance and Motivation
                      </h3>
                    </div>
                    <div className="md:w-2/3">
                      <p className="text-gray-600">
                        Motivate and guide candidates to realize their dreams of
                        studying abroad, ensuring they receive the best possible
                        advice and assistance throughout the process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-20">
                <h2 className="text-4xl font-bold mb-8">
                  Our <span className="text-primary-brown">Commitment</span>
                </h2>
                <div className="space-y-8">
                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/3">
                      <h3 className="text-xl font-semibold text-gray-800">
                        Student-Centric Services
                      </h3>
                    </div>
                    <div className="md:w-2/3">
                      <p className="text-gray-600">
                        Guarantee comprehensive support services for students,
                        including admission assistance, visa documentation,
                        forex, travel, airport pick-up, accommodation,
                        scholarship opportunities, and educational loan
                        guidance.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/3">
                      <h3 className="text-xl font-semibold text-gray-800">
                        Experienced Counseling
                      </h3>
                    </div>
                    <div className="md:w-2/3">
                      <p className="text-gray-600">
                        Provide access to experienced and dedicated counselors
                        who understand applicants' needs, monitor their
                        progress, and offer personalized guidance throughout the
                        application process.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/3">
                      <h3 className="text-xl font-semibold text-gray-800">
                        Global Network
                      </h3>
                    </div>
                    <div className="md:w-2/3">
                      <p className="text-gray-600">
                        Maintain strong alliances with reputable universities
                        and colleges worldwide, ensuring students have
                        opportunities to study in countries with high
                        educational standards, and facilitate direct
                        interactions with international university delegates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="py-16">
        <ContactForm />
      </section>
    </section>
  );
};

export default AboutPage;
