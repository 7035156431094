import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import StudyAbroadPage from "./pages/StudyAbroad/StudyAbroad";
import Navbar from "./components/Navbar";
import GalleryPage from "./pages/Gallery";
import CounsellingPage from "./pages/Counselling";
// import Canada from "./components/StudyAbroad/Canada/Canada";
import AboutPage from "./pages/About";
import Footer from "./components/footer";
import ContactUsForm from "./components/ContactForm";
import CountryOverview from "./pages/CountryOverview";
import ScrollToTop from "./components/ScrollToTop";
import Admin from "./pages/Admin";
import AdminLogin from "./pages/AdminLogin";
import ContactPage from "./pages/ContactUs";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Blog from "./pages/Blog";
import UserActivate from "./pages/auth/UserActivate";
import EnglishProficiency from "./components/EnglishProficiency/EnglishProficiency";
import IELTS from "./pages/IELTS";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import VisaPage from "./pages/Visa";
import EventDetailPage from "./pages/Event/EventDetails";
import EventsPage from "./pages/Event/Events";
import "./config/firebaseconfig";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        {/* Auth */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/activate-user/:token" element={<UserActivate />} />

        {/* General */}
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/blog/:blogId" element={<Blog />} />
        <Route path="/counselling" element={<CounsellingPage />} />
        <Route path="/study-abroad" element={<StudyAbroadPage />} />
        <Route path="/About" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/english-proficiency" element={<EnglishProficiency />} />
        <Route path="/ielts" element={<IELTS />} />
        <Route path="/visa" element={<VisaPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/event-details/:id" element={<EventDetailPage />} />

        {/* contact form */}
        <Route path="/contact-us" element={<ContactUsForm />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        {/* Country Pages */}
        <Route
          path="/country-overview/germany"
          element={<CountryOverview country={"germany"} />}
        />
        <Route
          path="/country-overview/usa"
          element={<CountryOverview country={"usa"} />}
        />
        <Route
          path="/country-overview/canada"
          element={<CountryOverview country={"canada"} />}
        />
        <Route
          path="/country-overview/europe"
          element={<CountryOverview country={"europe"} />}
        />
        <Route
          path="/country-overview/australia"
          element={<CountryOverview country={"australia"} />}
        />

        {/* Legal */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <FooterWrapper />
    </Router>
  );
}

function FooterWrapper() {
  const location = useLocation();

  // Don't render footer on admin routes
  if (location.pathname.startsWith("/admin")) {
    return null;
  }

  return <Footer />;
}

export default App;
